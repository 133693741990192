import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    Link,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {Add as AddIcon, AttachFile, CloudDone, ContentCopy, DeleteTwoTone, OpenInNew} from '@mui/icons-material';
import "./styles.css";
import FileUploadComponent from "../../components/FileUploadComponent";
import {FilesListView} from "../../components/FilesListView";
import {ZohoDetails} from "./ZohoDetails";
import ItalicTextDivider from "../../components/ItalicTextDivider";
import {copyToClipboard, isNull} from "../../utils/utils";
import {accountSelector, batchNumberSelector, downloadFile, fetchAccountList, fetchBatchList} from "../utils";
import {CustomPopper} from "../../components/CustomPopper";

const MultipleBillsComponent = (props) => {

  const getFiles = (billId) => {
    let files = [];
    Object.values(bills[billId].entries).forEach(entry => {
      files.push(entry.file);
    })
    return files;
  }

  const handleFileDownload = (fileId) => {
      downloadFile(fileId, true);
  }
    const [accountList, setAccountList] = useState([])
    const [batchList, setBatchList] = useState([])
    const [accountListSearchMeta, setAccountListSearchMeta] = useState({
        searchQuery: ''
    });
    const [batchListSearchMeta, setBatchListSearchMeta] = useState({
        searchQuery: ''
    });

    useEffect(() => {
        fetchAccountList(setAccountList, accountListSearchMeta.searchQuery);
    }, [accountListSearchMeta]);
    useEffect(() => {
        fetchBatchList(setBatchList, batchListSearchMeta.searchQuery);
    }, [batchListSearchMeta]);

    const handleAccountListSearchChange = (e) => {
        setAccountListSearchMeta({
            ...accountListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleBatchListSearchChange = (e) => {
        setBatchListSearchMeta({
            ...batchListSearchMeta,
            searchQuery: e.target.value,
        });
    }

  const {disabled, bills, addBill, removeBill, deleteBillEntry, handleBillsFileChange, uploadedBills={},
        deleteUploadedBill, handleBillNameUpdate, handleBillDescriptionUpdate, zohoAmountDiff, handleAccountChange,
      handleBatchChange, invoiceList, handleInvoiceListSearchChange} = props;

  let uploadedBillsViewComponent = null;
  if (Object.keys(uploadedBills).length > 0) {
    uploadedBillsViewComponent = (
        <div style={{mb: 4}}>
          {Object.keys(uploadedBills).map((billId, rowIndex) => (
            <Card key={billId} variant="outlined" sx={{mb: 2}}>
              <CardContent
                  sx={{
                    padding: '5px !important', paddingLeft: "10px", display: "flex",
                    alignItems: "center", justifyContent: "space-between",
                  }}>
                <Box sx={{width:'60%'}}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Box>
                      <TextField
                          disabled={true}
                          onChange={(event) => handleBillNameUpdate(billId, event.target.value)}
                          InputProps={{sx: {maxHeight: "30px", fontSize: 14, padding: 0}}}
                          value={uploadedBills[billId].name}
                          placeholder={"Enter invoice number"}
                      >
                      </TextField>
                      <Tooltip title={"Copy bill ID"}>
                        <ContentCopy
                            onClick={() => copyToClipboard(billId)}
                            sx={{fontSize: 16, marginLeft: "5px", marginTop: "5px", cursor: "pointer"}}>
                        </ContentCopy>
                      </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <Link href={uploadedBills[billId].link} target="_blank" rel="noopener">
                        <OpenInNew></OpenInNew>
                      </Link>
                      <Tooltip title={"Saved in DB"}>
                        <CloudDone sx={{color: "#1D8348", ml: 2}}></CloudDone>
                      </Tooltip>
                      <Tooltip title={"Delete from DB"}>
                        <IconButton disabled={disabled} color="error" onClick={() => deleteUploadedBill(billId)}>
                          <DeleteTwoTone/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                    <TextField
                        disabled={true}
                        fullWidth
                        multiline
                        label="Description-Fill in bill details, discounts and amount mismatch reason etc. (Optional)"
                        name="description"
                        value={uploadedBills[billId].description}
                        onChange={(event) => handleBillDescriptionUpdate(billId, event.target.value)}
                        minRows={1}
                        sx={{mt: 1, mb: 1}}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            {accountSelector(true, accountList, uploadedBills[billId].account, handleAccountChange, handleAccountListSearchChange, billId)}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            {batchNumberSelector(true, batchList, uploadedBills[billId].batchNumber, handleBatchChange, handleBatchListSearchChange, billId)}
                        </Box>
                    </Box>
                  <FilesListView
                      files={Object.values(uploadedBills[billId].entries)}
                      showRemoveIcon={false}
                      showCloudIcon={false}
                      uploaded={true}
                      showDownloadIcon={true}
                      handleFileDownload={handleFileDownload}
                  />
                </Box>
                <Box sx={{width: "1px", height: "100%", backgroundColor: "#bbb", borderRadius: "2px"}}></Box>
                <Box sx={{width: "40%", height: "100%", paddingLeft: "10px", display: "flex", alignItems: "center"}}>
                  <ZohoDetails zohoDetails={uploadedBills[billId]["zoho_details"]}/>
                </Box>
              </CardContent>
            </Card>
          ))}
    </div>);
  }

  let zohoDiffComponent = null;
  if (!isNull(zohoAmountDiff)) {
      zohoDiffComponent = (
          <div
              style={{
                marginLeft: "12px", padding: "2px", borderRadius: "3px",
                backgroundColor: zohoAmountDiff.zoho_diff_exceeded ? "#E6B0AA" : "#D1F2EB"}}>
            <Typography>
              (Bank Amount - Zoho Amount) : {zohoAmountDiff.bank_amount_sum - zohoAmountDiff.zoho_amount_sum}
            </Typography>
          </div>
      )
  }

    return (
      <div className={"card-box-shadow"}
           style={{padding: '20px'}}
      >
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h4">Bills</Typography>
          {zohoDiffComponent}
        </Box>
        <Box p={2}>
          {uploadedBillsViewComponent}
          <ItalicTextDivider text={"Add new bills"}></ItalicTextDivider>
          <div>
            {Object.keys(bills).map((billId, rowIndex) => (
                <Card key={billId} variant="outlined" sx={{mb: 2}}>
                  <CardContent sx={{padding: '2px', paddingLeft: "10px"}}>
                    <Box display="flex" justifyContent="space-between" alignItems="stretch" mb={1}>
                      <Box mt={1} sx={{flex: 1}}>
                          {invoiceList ?
                          <Autocomplete
                              freeSolo
                              options={invoiceList}
                              onInputChange={(event, newValue) => handleBillNameUpdate(billId, newValue)}
                              value={bills[billId].name}
                              getOptionLabel={(option) => {
                                  console.log(option);
                                  return option.name || option}}
                              PopperComponent={CustomPopper}
                              renderInput={(params) =>
                                  <TextField {...params} label="Enter Invoice Number" variant="outlined"
                                             onChange={handleInvoiceListSearchChange}
                                  />
                              }
                              renderOption={(props, option) => (
                                  <li {...props} onClick={() => window.open(option.link, '_blank')}>
                                      {option.name}
                                  </li>
                              )}
                          /> :
                              <TextField
                                  label={"Invoice Number"}
                                  onChange={(event) => handleBillNameUpdate(billId, event.target.value)}
                                  value={bills[billId].name}
                                  placeholder={"Enter invoice number"}
                                  />}
                      </Box>
                        <Box mt={1} sx={{flex: 1, alignItems: "right"}}>
                        <IconButton onClick={() => removeBill(billId)}>
                        <DeleteTwoTone/>
                      </IconButton>
                        </Box>
                    </Box>
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: 2,
                              gap: 2,
                          }}
                      >
                          <Box sx={{ flex: 1 }}>
                              {accountSelector(false, accountList, bills[billId].account, handleAccountChange, handleAccountListSearchChange, billId)}
                          </Box>
                          <Box sx={{ flex: 1 }}>
                              {batchNumberSelector(false, batchList, bills[billId].batchNumber, handleBatchChange, handleBatchListSearchChange, billId)}
                          </Box>
                      </Box>
                      {
                          Object.values(bills[billId].entries).length > 0 ? (
                          <TextField
                              fullWidth
                              multiline
                              label="Description-Fill in bill details, discounts and amount mismatch reason etc. (Optional)"
                              name="description"
                              value={bills[billId].description}
                              onChange={(event) => handleBillDescriptionUpdate(billId, event.target.value)}
                              minRows={2}
                              sx={{mt: 1, mb: 1, maxWidth: "80%"}}
                           />
                          ) : null
                      }
                    <FilesListView
                        files={Object.values(bills[billId].entries)}
                        removeHandler={(entryId) => deleteBillEntry(billId, entryId)}
                    />
                    <FileUploadComponent
                        index={"multi-bills-" + billId}
                      files={getFiles(billId)}
                      text={"Attach File"}
                      handleFileChange={(event) => handleBillsFileChange(event.target.files, billId)}
                      showFiles={false}
                      buttonStyles={{variant: 'text', startIcon: (<AttachFile/>)}}/>
                </CardContent>
              </Card>
          ))}
        </div>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button disabled={disabled} variant="outlined" startIcon={<AddIcon/>} onClick={addBill}>
            Add New Bill
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default MultipleBillsComponent;
